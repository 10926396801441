<template>
  <vue-perfect-scrollbar
    :settings="{ suppressScrollX: true, wheelPropagation: false }"
    class="h-100 rtl-ps-none ps scroll"
    style="height: 100%"
  >
    <div class="pa-5 relative">
      <v-app-bar
        :color="$vuetify.theme.dark ? 'dark' : 'grey lighten-5'"
        :dark="$vuetify.theme.dark"
        class=""
        fixed
        flat
      >
        <div class="d-flex justify-space-between align-center w-full">
          <h6 class="ma-0">
            {{ patientData && patientData.nameFull }} Care Note
          </h6>
          <slot name="userDrawerCloseButton" />
        </div>
      </v-app-bar>
    </div>

    <div class="mainStyle">
      <div class="container single">
        <div class="table-top-ui">

          <v-btn color="primary" depressed small v-if="!isOpen" @click="clickAdd">Add</v-btn>
          <CareNoteForm v-if="isOpen" ref="refCareNoteForm" :careNoteData="targetModel" :isNew="isNew" />

          <div class="myBtnPos" v-if="isOpen">
            <span class="wGap"></span>
            <v-btn color="primary" depressed small @click="saveData">
              {{ targetModel.cn_id > 0 ? 'UPDATE' : 'SAVE' }}
            </v-btn>
            <span class="wGap"></span>
            <v-btn color="primary" depressed small v-if="targetModel && targetModel.cn_id > 0" 
              @click="deleteData">DELETE</v-btn>
            <span class="wGap"></span>
            <v-btn color="primary" outlined small @click="clickCancel">CANCEL</v-btn>
          </div>
        </div>

        <DataTable
          ref="DataTable"
          :loading="loading"
          :loadingText="loadingText"
          :tableKey="'rowSeq'"
          :footerHide="false"
          :singleSelect="true"
          :showSelect="false"
          :columns="careNoteTableColumns"
          :inputData="careNoteData"
          @clickRow="tableClickRow"
      />
      </div>
    </div>
  </vue-perfect-scrollbar>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import DataTable from "@/components/commonV2/table/DataTable.vue";
import CareNoteForm from './comp/CareNoteForm.vue';

const modelService = require('@/utils/dataModel.js');
const formatService = require('@/utils/format.js');
const requireService = require('@/utils/requiredValue.js');

export default {
  components: {
    DataTable,
    CareNoteForm,
  },
  data() {
    return {
      loading: false,
      loadingText: "Loading list...",
      isOpen: false,
      isNew: false,
      patientData: null,
      careNoteData: [],
      careNoteTableColumns: formatService.careNoteTableColumns(),
      targetModel: modelService.patientCareNoteModel(),
      requiredMsg: requireService.requiredFieldAlertMessage(),
    };
  },
  methods: {
    _showDrawer(_patientData) {
      console.log(_patientData);
      this.isOpen = false;

      // 최종바인딩.
      this.patientData = _patientData;
      this.targetModel = modelService.patientCareNoteModel();
      this.targetModel.user_id = _patientData.user_id;
      this.targetModel.hospital_id = _patientData.hospital_id;
      this.getData();
    },
    // 데이터읽기
    async getData() {
      this.patientData.endpoint = 'patient/careNote';
      await this.getDataQuery(this.patientData).then((resP) => {
        if (resP === undefined) return;
        let res = resP.data;
        if (0 < res.length) {
          res = res.map((item, index) => ({
            ...item,
            no: res.length - index,
            diffSecLabel: this.$helper.secondsToTime(item.diffSec, true),
            regDateLabel: this.$helper.changeDateToStringForDp(item.regDate, true),
            dpDateLabel: this.$helper.changeDateToForUpdateDp(item.regDate, item.updDate),
          }));
        }
        this.careNoteData = res;
      });
    },
    tableClickRow(item) {
      console.log('', item);
      this.isOpen = true;
      this.isNew = false;
      this.targetModel = item;
      this.targetModel.dueDateTimeStartDp =
        this.$helper.changeDateToStringForDp(this.targetModel.dueDateTimeStart, true);
      this.targetModel.dueDateTimeEndDp =
        this.$helper.changeDateToStringForDp(this.targetModel.dueDateTimeEnd, true);
    },
    deleteData() {
      if (!confirm('delete?')) return;
      this.targetModel.useYN = 'N';
      this.saveProcess();
    },
    saveData() {
      // 필수 체크
      // if (!this.$refs.refCareNoteForm.$refs.form.validate()) return;
      console.log('this.targetModel', this.targetModel);
      
      let tOj = this.$refs.refCareNoteForm.$refs;
      let msg = `Care Time Date/Times ${this.requiredMsg}`;
      if (!this.$helper.requiredCheck(this.targetModel.careTimeSet.date, null, msg, false)) return;

      msg = `Program ${this.requiredMsg}`;
      if (!this.$helper.requiredCheck(this.targetModel.program, null, msg, false)) return;

      msg = `Active Log ${this.requiredMsg}`;
      if (!this.$helper.requiredCheck(this.targetModel.activeLog, null, msg, false)) return;

      msg = `Active Log Type ${this.requiredMsg}`;
      if (!this.$helper.requiredCheck(this.targetModel.activeLogType, null, msg, false)) return;

      // console.log('saveData', this.targetModel);
      // return;

      if (!confirm("Care Note Save?")) return;
      this.saveProcess();
    },
    saveProcess() {
      this.targetModel.endpoint = 'patient/careNote';
      this.targetModel.hospital_id = this.patientData.hospital_id;
      this.targetModel.user_id = this.patientData.user_id;
      this.saveDataQuery(this.targetModel).then((res) => {
        if (res === undefined) return;
        this.$helper.showTostMessage('', 'Success', 'success');
        this.isOpen = false;
        this.getData();
        this.$emit('saveComplete');
      });
    },
    clickAdd() {
      this.isOpen = true;
      this.isNew = true;
      this.targetModel = modelService.patientCareNoteModel();
      console.log('targetModel ', this.targetModel);
    },
    clickCancel() {
      this.isOpen = false;
      this.targetModel = modelService.patientCareNoteModel();
      console.log('', this.targetModel.cn_id);
    },
    ...mapActions(['getDataQuery', 'saveDataQuery', 'clearAnswer']),
  },
};
</script>
<style lang="scss">
.myBtnPos {
  padding: 5px;
  text-align: center !important;
}
.wGap {
  display: inline-block;
  width: 10px;
}
</style>